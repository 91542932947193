import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { graphql } from "gatsby";
import "../data/sections-fragment";
export const query = graphql`
  query {
    mdx(slug: { eq: "unternehmen" }) {
      slug
      frontmatter {
        title
        footer {
          showContactFooter: show_contact_footer
          showContactColumn: show_contact_column
        }
        backgrounds {
          ...BackgroundFragment
        }
        sections {
          ...MdxFrontmatterSectionsFields
        }
      }
    }
  }
`;
export const _frontmatter = {
  "title": "Unternehmen",
  "footer": {
    "show_contact_footer": true,
    "show_contact_column": false
  },
  "backgrounds": [{
    "rows": "2 / 4",
    "gradient": "linear-gradient(to-b, rgba(255,255,255,0), gray.50)"
  }],
  "sections": [{
    "type": "header_company",
    "slug": "header",
    "title": "Unternehmen",
    "spacing": {
      "top": ["24vh", null, "26vh", null, "33vh"]
    },
    "image": {
      "src": "images/header-unternehmen.jpg",
      "alt": "Unternehmen",
      "position": "22% 46%"
    },
    "background_image": {
      "src": "images/beton.jpg",
      "position": "100% 100%"
    },
    "columns": [{
      "headline": "Wir bauen Garagen.",
      "content": "Für uns ist eine Garage nicht nur ein Ort, an dem Sie Ihr Auto und andere Dinge unterbringen. Für uns ist eine Garage Ausdruck von Individualität. Der Raum, der Ihr Wohnumfeld stimmig ergänzt. Der Raum, der Platz für Ihre Ideen bietet. Der Raum, der passgenau für Sie von uns entwickelt wird."
    }, {
      "headline": "Wir sind Problemlöser.",
      "content": "Sie nennen uns die Rahmenbedingungen, wir finden die optimale Lösung. Das Spektrum reicht von einfachen Garagen bis hin zu sehr individuellen Lösungen, unter Nutzung vieler möglicher Optionen. Bei uns bekommen Sie von der Planung bis zur Umsetzung alles aus einer Hand."
    }, {
      "headline": "Wir sind Spezialisten.",
      "content": "Auf dem Weg zu Ihrer Garage begleiten wir Sie mit unserem reichhaltigen Wissen und unserer weitreichenden Erfahrung. Hier können Sie sich auf uns verlassen. Ihre Zufriedenheit als Kunde ist unser täglicher Anspruch und jede Empfehlung die Bestätigung der Erreichung dieses Ziels."
    }, {
      "headline": "Wir sind ein Familienunternehmen.",
      "content": "Gemeinsam schaffen wir Werte — extern sichtbare mit unseren Projekten und intern gelebte durch unsere familiäre Firmenkultur. Bei uns sind die Wege kurz und die Kommunikation lebendig. Wir sorgen für ein gutes Miteinander. Das ist die Basis unserer erfolgreichen Arbeit für Sie als Kunde."
    }],
    "listColumn": {
      "headline": "Was Kunden an uns schätzen:",
      "content": "* Wir sind spezialisiert auf Garagen\n* Wir sind kein typisches Bauunternehmen\n* Wir sind in der Region, in der wir arbeiten, verwurzelt\n* Wir kümmern uns um den kompletten Prozess\n* Wir sorgen für eine zeitnahe Abwicklung\n* Wir sichern die fachgerechte Umsetzung\n* Wir halten Termine und sind verlässlich\n* Wir entwickeln uns durch unsere Erfahrungen weiter"
    },
    "imageColumn": {
      "src": "images/unternehmen-grid.jpg",
      "alt": "Unternehmen"
    }
  }, {
    "type": "team",
    "slug": "team",
    "title": "Team",
    "spacing": {
      "top": ["40", null, "48"],
      "bottom": ["40", null, "48"]
    },
    "members": [{
      "first_name": "Florian",
      "last_name": "Kienzler",
      "image": {
        "src": "images/florian-kienzler.jpg"
      }
    }, {
      "first_name": "Markus",
      "last_name": "Kienzler",
      "image": {
        "src": "images/markus-kienzler.jpg"
      }
    }, {
      "first_name": "Christiane",
      "last_name": "Preis",
      "image": {
        "src": "images/team-placeholder-f.jpg"
      }
    }]
  }, {
    "type": "career",
    "slug": "karriere",
    "title": "Karriere",
    "spacing": {
      "bottom": ["40", null, "48"]
    },
    "text": "Unser Unternehmen entwickelt sich. Und wir legen Wert auf Qualität. Dafür brauchen wir engagierte Mitarbeiter, die unsere Ziele teilen und die passende berufliche Basis und Erfahrung mitbringen.",
    "cta_text": "**Das könnten Sie sein?** Dann freuen wir uns jederzeit auf Ihre Initiativbewerbung, egal ob Sie an unserem Erfolg auf der Baustelle oder im Büro mitwirken möchten.",
    "cta": {
      "url": "mailto:karriere@kienzler-betontechnik.de",
      "label": "Jetzt bewerben"
    },
    "images": [{
      "src": "images/karriere-1.jpg"
    }, {
      "src": "images/karriere-2.jpg"
    }, {
      "src": "images/karriere-3.jpg"
    }]
  }]
};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      